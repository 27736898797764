export enum ConnectionStateEnum {
  GOOD = 'GOOD',
  POOR = 'POOR',
  NOT_VALID = 'NOT_VALID',
  NO_INTERNET = 'NO_INTERNET'
}

export interface ConnectedStoreInterface {
  connectionState: ConnectionStateEnum;
  setConnectionState: (value: ConnectionStateEnum) => void;
}
